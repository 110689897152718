.jump-menu {
  display: flex;
  justify-content: center;
  padding-left: 10%;
  margin-top: 2rem;
}

@media only screen and (max-width: 960px) {
  .jump-menu {
    display: none;
  }

  .edit-graphable-container {
    justify-content: center;
  }
}
