::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9 !important;
  outline: 1px solid #868686 !important;
}
