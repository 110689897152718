@use './assets/css/themes.scss' as *;
@use '@nebular/theme/styles/globals' as *;
@import '~quill/dist/quill.snow.css';
@import '~eva-icons/style/eva-icons.css';

@import './assets/css/custom-webkit-scroll.scss';
@import './assets/css/object-list.scss';
@import './assets/css/context-menu';
@import './assets/css/jump-menu';
@import './assets/css/list-item';
@import './assets/css/form-controls';
@import './assets/css/disable-text-selection';
@import './assets/css/icon';
@import './assets/css/tab';

@include nb-install() {
  @include nb-theme-global();
}
